.landingWrapper {
  height: 80vh;
  display: flex;
  width: 100%;
  padding-top: 80px;
}

.left {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 5vw;
  background: url("./assets/leftimage.svg") center center/cover no-repeat;
}
.left h1 {
  font-weight: 600;
  color: white;
  font-size: 6vw;
  line-height: 1;
  margin-bottom: -1vw;
}
.left .headingName{
  display: flex;

}
.left .headingName >div{
  display: flex;
  flex-direction: column;
}
.left .headingName >div .hyper{
  width: 25px;
  height: 20px;
}
.left h2 {
  font-weight: 400;
  color: white;
  font-size: 6vw;
  line-height: 1;
}
.left p {
  font-weight: 400;
  color: white;
  font-size: 27px;
  line-height: 1;
  letter-spacing: 10px;
  color: rgb(255, 255, 255);
}
.left a{
  display: none;
}
.right {
  width: 40%;
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 900px) {
  .left {
    width: 100%;
  }
  .left h1 {
    font-size: 10vw;
    margin-bottom: -1vw;
  }
  .left h2 {
    font-size: 10vw;
  }
  .right {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .left {
    padding: 8vw;
  }
  .left p {
    font-size: 23px;
  }
}

@media screen and (max-width: 500px) {
  .landingWrapper {
    height: 100vh;
    position: relative;
  }
  .left {
    padding: 0vw;
    align-items: center;
    justify-content: center;
  }
  .left h1 {
    font-size: 12vw;
    margin-bottom: -1vw;
  }
  .left h2 {
    font-size: 12vw;
  }
  .left a{
    display: block;
    position: absolute;
    bottom: 12%;
  }
}

button {
  background-color: transparent;
  border: none;
}

.mScrollArrows {
  display: block;

  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);

  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  margin: 0 0 3px 0px;
  width: 20px;
  height: 20px;
}

.unu {
  margin-top: 0px;
}

.unu,
.doi,
.trei,
.quad {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;
}

.unu {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;

  animation-direction: alternate;
  animation-delay: alternate;
  margin-top: -10px;
}

.doi {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.2s;
  animation-direction: alternate;

  margin-top: -13px;
}

.trei {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.3s;
  animation-direction: alternate;

  margin-top: -13px;
}
.quad {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.4s;
  animation-direction: alternate;

  margin-top: -20px;
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}
@-o-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}
@keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
