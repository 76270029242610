.gallery {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 3vh;
}

.header {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0px 8vw;
}
.header .line {
  width: 5px;
  height: 6vw;
  background-color: #000;
}
.header h1 {
  font-size: 7vw;
  font-weight: 300;
  text-transform: uppercase;
  color: rgba(231, 89, 19, 1);
}
.row {
  transition: all 2s ease-in-out;
}

.imgContainer {
  padding: 0.5rem;
  height: 440px;
  width: 300px;
  position: relative;
}
.imgContainer a {
  z-index: 988;
  height: 440px;
  width: 300px;
  bottom: 0;
  position: absolute;
}
.gallery .imgContainer img {
  height: 400px;
  width: 100%;
}

.imgContainer h2 {
  color: rgb(0, 0, 0);
  font-weight: 300;
  text-transform: uppercase;
}
@media screen and (max-width: 900px) {
  .imgContainer {
    padding: 0.5rem;
    height: 330px;
    width: 250px;
  }
  .imgContainer a {
    height: 330px;
    width: 250px;
  }
  .gallery .imgContainer img {
    height: 300px;
    width: 100%;
  }

  .imgContainer h2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 700px) {
  .header h1 {
    font-size: 35px;
  }
}
