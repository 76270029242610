.aboutWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.wrapper {
  padding: 4vh 8vw;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  align-items: center;
}
.TopContainer {
  display: flex;
}

.TopContainer h1 {
  font-size: 12vw;
  font-weight: 200;
  color: rgba(229, 149, 76, 0.07);
}
.TopContainer img {
  border: 0.8vw solid white;

  width: 38vw;
}
.ballsImage {
  position: absolute;
  width: 6vw;
  height: 100px;
  object-fit: contain;
  left: 13vw;
  top: 21vh;
}
.BottomContainer {
  display: flex;
  flex-direction: column;
  gap: 6vh;
  background-color: #E5954C;
  border: 1.5vw solid #F2C99E;
  color: white;
  padding: 2vh 4vw 4vh;
  
  z-index: -1;
  top: 14vw;
  width: 100%;
  margin-top: -13vw;
}
.BottomContainer > div {
  display: flex;
  align-items: center;
  gap: 1.5vw;
}

.BottomContainer > div .line {
  width: 5px;
  height: 6.5vw;
  background-color: rgba(242, 201, 158, 1);
}
.BottomContainer > div h1 {
  font-size: 7vw;
  font-weight: 300;
}

.BottomContainer > p {
  font-size: 1.4vw;
  text-align: justify;
  font-weight: 300;
}

@media screen and (max-width: 1000px) {
  .BottomContainer > p {
    font-size: 1.9vw;
  }
  .wrapper {
    height: 53vw;
  }
}
@media screen and (max-width: 840px) {

}
@media screen and (max-width: 700px) {
  .aboutWrapper{
    gap: 0vh;
  }
  .wrapper {
    height: fit-content;
  }
  .ballsImage {
    display: none;
  }
  .TopContainer {
    align-items: center;
    justify-content: center;
  }
  .TopContainer h1 {
    display: none;
  }
  .TopContainer img {
    min-width: 350px;
    border: none;
    width: 100%;
  }
  .BottomContainer {
    position: relative;
    top: 0;
    gap: 1.5vh;
    width: 90%;
    min-width: 350px;
    margin-top: -4vh;
    z-index: 880;
  }
  .BottomContainer > div {
    gap: 10px;
  }
  .BottomContainer > div h1 {
    font-size: 35px;
  }
  .BottomContainer > p {
    font-size: 16px;
  }
}
.wrapperCEO {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  
}

.wrapperCEO .TopContainer h1 {
  color: rgba(229, 149, 76, 0.07);
}
.wrapperCEO .TopContainer img {
  width: 30vw;
  border-color: rgba(242, 201, 158, 1);
}

.wrapperCEO .ballsImages {
 
  width: 6vw;
  height: 100px;
  object-fit: contain;
}
.ballsImages {
  position: absolute;
  top: 20vh;
  right: 11vw;
}
.wrapperCEO .line {
  width: 5px;
  height: 6.5vw;
  background-color: rgba(229, 149, 76, 1);
}
.BottomContainers {
  width: 85%;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 4vh;
  background-color: #fff;
  border: 1.5vw solid rgba(229, 149, 76, 1);

  padding: 2vh 4vw 4vh;
  margin-top: -12vw;
  position: relative;
  z-index: -1;
  margin-bottom: 4vh;
}
.BottomContainers > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.BottomContainers > div h1 {
  font-size: 7vw;
  font-weight: 300;
}
.BottomContainers > p {
  font-size: 1.4vw;
  font-weight: 300;
}



@media screen and (max-width: 1000px) {
  .BottomContainers > p {
    font-size: 1.9vw;
  }

}

@media screen and (max-width: 700px) {
  .wrapperCEO{
      padding: 4vh 8vw;
  }
  .BottomContainers {
    margin-top: 0;
    margin-bottom: 1vh;
  }

  .ballsImages {
    display: none;
  }
  .TopContainer {
    align-items: center;
    justify-content: center;
  }
  .TopContainer h1 {
    display: none;
  }
  .wrapperCEO .TopContainer img {
    min-width: 350px;
    border: none;
    width: 100%;
  }
  .BottomContainers {
    position: relative;
    top: 0;
    gap: 1.5vh;
    width: 90%;
    min-width: 350px;
    z-index: 880;
    margin-top: -4vh;
  }
  .BottomContainers > div {
    gap: 10px;
  }
  .BottomContainers > div h1 {
    font-size: 35px;
  }
  .BottomContainers > p {
    font-size: 16px;
  }
}