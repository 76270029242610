.galleryWrapper {
  display: flex;
  flex-direction: column;
  padding: 3vh 0vw 0vh;
  justify-content: center;
  width: 100%;
  gap: 3vh;
}
.header {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0vw 8vw;
  width: 100%;
  justify-content: space-between;
}
.header > div {
  display: flex;
  gap: 20px;
  align-items: center;
}
.header .line {
  width: 5px;
  height: 6vw;
  background-color: rgba(229, 149, 76, 1);
}
.header h1 {
  font-size: 7vw;
  font-weight: 300;
  text-transform: uppercase;
}

.contentWrapper {
  display: flex;

  width: 100%;
  height: 100%;

  border-top: 0.8px solid rgba(136, 136, 136, 0.7);
  overflow-x: scroll;
}
.contentWrapper > div {
  padding: 20px;
  border-right: 0.8px solid rgba(136, 136, 136, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px;
  width: 340px;
  text-align: left;
}

.contentWrapper > div img {
  width: 300px;
  height: 300px;
}
.contentWrapper > div h2{
  font-weight: 500;
}
.contentWrapper > div .line {
  height: 4px;
  width: 17%;
  background-color: rgba(229, 149, 76, 1);
}
.header .NavigateButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.NavigateButton button {
  cursor: pointer;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border: 1px solid black;
  font-size: 20px;
}
.NavigateButton button .button1 {
}
@media screen and (max-width: 1100px) {
  .NavigateButton button {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 700px) {
  .header h1 {
    font-size: 35px;
  }
  .NavigateButton button {
    width: 35px;
    height: 35px;
  }
}
