/* footer */
.footerWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
     margin-bottom: 20px;
     text-align: center;
     text-transform: uppercase;
}
.footerWrapper>div{
    width: 95%;
}
