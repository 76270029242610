.galleryWrapper {
  display: flex;
  flex-direction: column;
  padding: 0vh 8vw 3vh;
  justify-content: center;
  width: 100%;
  gap: 3vh;
}
.header {
  display: flex;
  gap: 20px;
  align-items: center;
}
.header .line {
  width: 5px;
  height: 6vw;
  background-color: rgba(242, 201, 158, 1);
}
.header h1 {
  font-size: 7vw;
  font-weight: 300;
  text-transform: uppercase;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.contentWrapper > div {
  min-width: 200px;

  position: relative;
}

.contentWrapper > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container {
  height: 42vh;
  overflow: hidden;
  border-bottom: 7px solid rgba(229, 149, 76, 1);
}

.container0 {
  /* The first container */
  width: 43%;
}

.container1 {
  /* The second container */
  width: 55%;
}

.container2 {
  /* The third container */
  width: 54%;
}

.container3 {
  /* The fourth container */
  width: 44%;
}

@media screen and (max-width: 900px) {
  .contentWrapper > div {
    height: 30vh;
  }
}
@media screen and (max-width: 700px) {
  .header h1 {
    font-size: 35px;
  }
  .contentWrapper {
    gap: 10px;
  }
  .container0 {
    width: 42%;
  }

  .container1 {
    width: 53%;
  }

  .container2 {
    width: 53%;
  }

  .container3 {
    width: 42%;
  }
}
@media screen and (max-width: 530px) {
  .container0,
  .container1,
  .container2,
  .container3 {
    width: 100%;
  }
}
